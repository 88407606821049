import { Suggestion, SuggestionActionTimeFrame } from 'blue-project-types'

const en = {
	phishingExport: {
		title: 'Phishing pdf export - {{simulation}}',
		overview: {
			overview: 'Overview',
			templates: 'Templates',
			simulationFunnel: 'Simulation funnel',
		},
		targets: {
			title: 'Targets',
			failedSimulations: 'Failed the simulation',
			failedDesc:
				'People who failed to respond appropriately to phishing simulation emails by clicking on malicious links or entering sensitive data.',
			reported: 'Reported phishing',
			reportedDesc: 'People who successfully identified and reported phishing simulation emails.',
			notReportedDesc:
				'People who did not report phishing simulation emails after encountering them.',
			notReported: 'Not reported',
			table: {
				firstName: 'First name',
				lastName: 'Last name',
				simulationsSent: 'Simulations sent',
				simulationsFailed: 'Simulations failed',
				simulationsPassed: 'Simulations passed',
			},
		},
	},
	courseOverviewExport: {
		title: 'Assignment pdf export - {{courseName}}',
		overview: 'Overview',
		lessons: 'Lessons',
		learners: 'Learners',
		nonCompleted: 'Not completed',
		nonCompletedDescription: `Learners who have been invited to the course but haven't completed it by the designated deadline.`,
		passed: 'Passed the course',
		passedDescription: 'Learners who have successfully completed and passed the course.',
		failed: 'Failed the course',
		failedDescription: `Learners who have been invited to the course but haven't successfully completed it.`,
	},
	exportLanguageSelectionModal: {
		title: 'Export to pdf',
		emailLanguageSelectLabel: 'Pdf language',
		emailLanguageSelectPlaceholder: 'Choose language used in pdf',
		emailLanguageOptions: {
			en: 'English',
			sr: 'Serbian',
		},
	},
	RiskManagementExport: {
		title: 'Cybersecurity Risk Management Report',
		dateOfReport: 'Date of Report: {{reportDate}}',
		executiveSummary: 'Executive Summary',
		noRiskScoreAlert: {
			title: 'Risk Score Not Available',
			subtitle:
				'"The risk score for this organization hasn’t been calculated or there was an error retrieving it. Please try again later or contact support for assistance.".',
		},
		keyAction: {
			title: 'Key actions taken',
			subtitle:
				'Prioritized actions for addressing critical vulnerabilities, improving training, and managing credential weaknesses.',
			courses: {
				title: 'Awareness Courses',
				description: 'New cybersecurity awareness Courses launched',
			},
			simulations: {
				title: 'Phishing Simulations',
				description: 'Phishing simulations launched towards employees',
			},
			nanoCampaigns: {
				title: 'Seif Nano Learning',
				description: 'Nano Learning campaigns launched',
			},
			leaks: {
				title: 'Solved Credential Leaks',
				description: 'Critical Credential Leaks resolved',
			},
		},
		recommendedActions: {
			title: 'Recommended actions',
			subtitle:
				'Recommended actions required in order to maintain organization’s security and keep your sensitive data secure.',
			subtitleNoActions: 'All recommended actions have been completed.',
			list: {
				title: {
					[SuggestionActionTimeFrame.Immediate]: 'Immediate actions (Today)',
					[SuggestionActionTimeFrame.ShortTerm]: 'Short-term actions (Next 7 days)',
					[SuggestionActionTimeFrame.LongTerm]: 'Long-term actions (Next 30 days)',
				},
				subtitle: {
					[SuggestionActionTimeFrame.Immediate]:
						'Immediate action is required to maintain the organization’s security and keep your sensitive data secure.',
					[SuggestionActionTimeFrame.ShortTerm]:
						'Address these actions soon to keep your organization’s security in good standing.',
					[SuggestionActionTimeFrame.LongTerm]:
						'Plan these actions for long-term security improvements.',
				},
				action: {
					[Suggestion.ExtendExpiredCourse]: {
						title: 'Extend Expired Courses',
						subtitle: `Some employees failed to finish their courses on time.`,
					},
					[Suggestion.RecreateExpiredCourse]: {
						title: 'Recreate Expired Courses',
						subtitle: `Some employees failed to finish their courses on time even after they were extended.`,
					},
					[Suggestion.ImproveLesson]: {
						title: 'Enhance Lesson Effectiveness',
						subtitle: 'Learners did not achieve a high performance score on a given lesson.',
					},
					[Suggestion.CreateFirstCourse]: {
						title: 'Employees waiting for Course enrollment',
						subtitle: 'Some employees still haven’t received any kind of training Course.',
					},
					[Suggestion.CreateFirstSimulation]: {
						title: 'Employees waiting for their first Phishing Simulation',
						subtitle: 'Some employees still haven’t received any Phishing Simulations.',
					},
					[Suggestion.NotifyPeopleAboutLeak]: {
						title: 'Compromised credential owners not notified',
						subtitle: 'Notify compromised credential owners to change passwords.',
					},
					[Suggestion.RecreateFailedCourse]: {
						title: 'Learners failed Course',
						subtitle: 'Resend invitations for course retake.',
					},
					button: 'Improve risk score',
					successMsg: 'Success', //izmeniti poruku
				},
			},
			linkComponent: {},
		},
		trainingCampaignsOverview: {
			title: 'Training Campaigns overview',
			courses: {
				title: 'Courses Summary',
				subtitle:
					'Key metrics from recent Training Campaigns, including course completion, learner performance, and overall success rates.',
				completionRate: {
					title: 'Course Completion rate',
					subtitle: 'Percentage of learners that completed the course, regardless of the score.',
				},
				passingRate: {
					title: 'Course passing rate',
					subtitle: 'Passing rate of learners, compared to the total number of learners.',
				},
			},
			nanoQuiz: {
				title: 'Seif Nano Quiz summary',
				subtitle:
					'Key metrics of concluded Nano Learning cycles, showing learner engagement rates and passing rates.',
				engagementRate: {
					title: 'Seif Nano Engagement rate',
					subtitle:
						'Number of learners who correctly answered quiz questions out of all participants.',
				},
				passingRate: {
					title: 'Seif Nano Quiz rate',
					subtitle: 'Number of correct answers compared to the total number of responses.',
				},
			},
			simulations: {
				title: 'Phishing Simulation results',
				subtitle:
					'Phishing test outcomes, including attack frequency, email interaction rates, and failure percentages.',
				progress: {
					total: 'Total Attacks',
					clicked: 'Clicked',
					failed: 'Failed',
					opened: 'Opened',
				},
			},
		},
		credentialLeakMonitoring: {
			title: 'Credential Leak monitoring',
			subtitle:
				'Overview of detected credential leaks and the number of leaks sanitized through immediate action.',
			detected: 'Credential leaks detected',
			unresolved: 'Credential leaks unresolved',
			resolved: 'Credential leaks resolved',
			notification: 'Weak password detected,  requires change of policy.',
		},
	},
}

export default en
