import {
	IPeopleOverviewResponse,
	IPeopleOverviewSuggestion,
	IPerson,
	Suggestion,
	SuggestionActionTimeFrame,
	getSuggestionTimeFrame,
} from 'blue-project-types'
import { useMemo } from 'react'

const getPersonDetails = (personId: string, people: IPerson[]) => {
	const person = people.find((person) => person.id === personId)
	if (!person) {
		return null
	}

	return person
}

const findItemWithMostPeople = <T extends { people: string[] }>(item: Array<T>): T => {
	const withMostPeople = item.reduce((prev, curr) => {
		if (curr.people.length > prev.people.length) {
			return curr
		}
		return prev
	}, item[0])

	return withMostPeople
}

const groupPeopleByCourses = (
	people: IPerson[],
	courseSuggestions: IPeopleOverviewSuggestion[],
) => {
	return courseSuggestions.reduce((peopleByCourseAcc, expiredCourse) => {
		if (!expiredCourse.courseId) {
			return peopleByCourseAcc
		}

		peopleByCourseAcc[expiredCourse.courseId] = (expiredCourse?.people || [])
			.map((personId: string) => getPersonDetails(personId, people))
			.filter((person) => !!person) as IPerson[]

		return peopleByCourseAcc
	}, {} as Record<string, IPerson[]>)
}

export interface IRiskScoreSuggestion {
	name: Suggestion
	meta: ISuggestionMeta
}

export interface ISuggestionMeta {
	people?: IPerson[] | string[]
	actionTimeFrame: SuggestionActionTimeFrame
	courseId?: string
	courseName?: string
	coursePeople?: Record<string, IPerson[]>
	lessonId?: string
	lessonName?: string
}

const useIdentifyRiskScoreSuggestions = ({
	peopleSummary,
	people,
}: {
	peopleSummary: IPeopleOverviewResponse
	people: IPerson[]
}) => {
	return useMemo(() => {
		const suggestions: IRiskScoreSuggestion[] = []

		if (!peopleSummary || !people) return suggestions

		if (
			peopleSummary.suggestions.createFirstCourse?.length &&
			peopleSummary.suggestions.createFirstCourse[0].people.length
		) {
			suggestions.push({
				name: Suggestion.CreateFirstCourse,
				meta: {
					people: peopleSummary.suggestions.createFirstCourse[0].people
						.map((personId: string) => getPersonDetails(personId, people))
						.filter((person) => !!person) as IPerson[],
					actionTimeFrame: getSuggestionTimeFrame(Suggestion.CreateFirstCourse),
				},
			})
		}

		if (
			peopleSummary.suggestions.createFirstSimulation?.length &&
			peopleSummary.suggestions.createFirstSimulation[0].people.length
		) {
			suggestions.push({
				name: Suggestion.CreateFirstSimulation,
				meta: {
					people: peopleSummary.suggestions.createFirstSimulation[0].people
						.map((personId: string) => getPersonDetails(personId, people))
						.filter((person) => !!person) as IPerson[],
					actionTimeFrame: getSuggestionTimeFrame(Suggestion.CreateFirstSimulation),
				},
			})
		}

		if (
			peopleSummary.suggestions.notifyPeopleAboutLeak?.length &&
			peopleSummary.suggestions.notifyPeopleAboutLeak[0].people.length
		) {
			suggestions.push({
				name: Suggestion.NotifyPeopleAboutLeak,
				meta: {
					people: peopleSummary.suggestions.notifyPeopleAboutLeak[0].people,
					actionTimeFrame: getSuggestionTimeFrame(Suggestion.NotifyPeopleAboutLeak),
				},
			})
		}

		if (peopleSummary.suggestions.extendExpiredCourse?.length) {
			suggestions.push({
				name: Suggestion.ExtendExpiredCourse,
				meta: {
					coursePeople: groupPeopleByCourses(people, peopleSummary.suggestions.extendExpiredCourse),
					actionTimeFrame: getSuggestionTimeFrame(Suggestion.ExtendExpiredCourse),
				},
			})
		}

		if (peopleSummary.suggestions.recreateExpiredCourse?.length) {
			suggestions.push({
				name: Suggestion.RecreateExpiredCourse,
				meta: {
					coursePeople: groupPeopleByCourses(
						people,
						peopleSummary.suggestions.recreateExpiredCourse,
					),
					actionTimeFrame: getSuggestionTimeFrame(Suggestion.RecreateExpiredCourse),
				},
			})
		}

		if (peopleSummary.suggestions.recreateFailedCourse?.length) {
			suggestions.push({
				name: Suggestion.RecreateFailedCourse,
				meta: {
					coursePeople: groupPeopleByCourses(
						people,
						peopleSummary.suggestions.recreateFailedCourse,
					),
					actionTimeFrame: getSuggestionTimeFrame(Suggestion.RecreateFailedCourse),
				},
			})
		}

		if (peopleSummary.suggestions.improveLesson?.length) {
			const improveLessonWithMostPeople = findItemWithMostPeople(
				peopleSummary.suggestions.improveLesson,
			)
			suggestions.push({
				name: Suggestion.ImproveLesson,
				meta: {
					lessonId: improveLessonWithMostPeople.lessonId,
					lessonName: improveLessonWithMostPeople.lessonName,
					people: improveLessonWithMostPeople.people
						.map((personId: string) => getPersonDetails(personId, people))
						.filter((person) => person) as IPerson[],
					actionTimeFrame: getSuggestionTimeFrame(Suggestion.ImproveLesson),
				},
			})
		}

		return suggestions
	}, [peopleSummary, people])
}

export default useIdentifyRiskScoreSuggestions
