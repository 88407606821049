import { Language, RiskScoreCategory } from 'blue-project-types'
import { PdfDownloadStatus } from '../context/pdf-exports/context'
import admin from '../features/admin/translate/en'
import auth from '../features/auth/translate/en'
import awareness from '../features/awareness/translate/en'
import { AwarenessStatus } from '../features/awareness/utils/types'
import dashboard from '../features/dashboard/translate/en'
import extensionReports from '../features/extension-reports/translate/en'
import groupAdministrations from '../features/group-admin/translate/en'
import inventory from '../features/inventory/translate/en'
import leaks from '../features/leaks/translate/en'
import library from '../features/library/translate/en'
import nanoLearn from '../features/nano-learn/translate/en'
import notifications from '../features/notifications/translate/en'
import organizations from '../features/organizations/translate/en'
import pdfExports from '../features/pdf-exports/translate/en'
import publicPages from '../features/public/translate/en'
import settings from '../features/settings/translate/en'
import simulations from '../features/simulations/translate/en'
import vulnerabilities from '../features/vulnerabilities/translate/en'
import waf from '../features/waf/translate/en'
import layout from '../layout/translate/en'

const common = {
	confirm: 'Confirm',
	cancel: 'Cancel',
	save: 'Save',
	back: 'Back',
	continue: 'Continue',
	finish: 'Finish',
	next: 'Next',
	skip: 'Skip',
	apply: 'Apply',
	remove: 'Remove',
	restore: 'Restore',
	send: 'Send',
	saveChanges: 'Save Changes',
	createPage: 'Create Page',
	add: 'Add',
	clear: 'Clear',
	edit: 'Edit',
	previewHTML: 'Preview HTML',
	copy: 'Copy',
	seeMore: 'See more',
	numbers: {
		2: 'two',
		100: 'hundred',
		1: 'one',
		8: 'eight',
	},
	more: 'more',
	time: {
		today: 'Today',
	},
	validation: {
		errors: {
			required: 'Required',
			requiredWithField: '{{field}} is required',
			moreThen0: 'Must be positive',
			between0And10: 'Between 0 and 10',
			assetRequired: 'Required to have at least one asset',
			providerRequired: 'Required to have at least one provider',
			textRequired: 'Text is required',
			min: '{{field}} must have at least {{numberOfCharacters}} characters',
			max: '{{field}} must have maximum {{numberOfCharacters}} characters',
			email: 'Invalid email',
			password: 'Must contain minimum 6 characters and at least one number',
			checked: '{{field}} must be checked',
			arrayMin: 'Minimum {{number}} selected {{item}}',
			numberMax: '{{field}} can have maximum value {{maxNumber}}',
			emailDuplicate: 'Duplicate email addresses are not allowed',
			validUrl: 'Please provide a valid URL.',
		},
	},
	errors: {
		generic: 'Error happened!',
	},
	pdfDownload: {
		error: 'Error happened',
		[PdfDownloadStatus.Downloading]: 'Downloading',
		[PdfDownloadStatus.PendingToBeCreated]: 'Generating PDF',
		[PdfDownloadStatus.ReadyToBeDownloaded]: 'Generating PDF',
	},
	messages: {
		noOptions: 'No options available',
		personDeleted: 'Person has been deleted from organization!',
	},
	imageUpload: {
		addImages: 'Add images',
		dragAndDropText: "Upload image <1>Drag'n'drop or click to add from computer</1>",
	},
	language: {
		[Language.En]: 'English',
		[Language.Sr]: 'Serbian',
		[Language.De]: 'German',
		[Language.Es]: 'Spanish',
		[Language.Sl]: 'Slovenian',
	},
	riskScoreStatus: {
		[RiskScoreCategory.Critical]: 'Critical',
		[RiskScoreCategory.High]: 'High',
		[RiskScoreCategory.Medium]: 'Medium',
		[RiskScoreCategory.Low]: 'Low',
		[RiskScoreCategory.VeryLow]: 'Very low',
	},
	riskScore: {
		[RiskScoreCategory.Critical]: 'Very high risk',
		[RiskScoreCategory.High]: 'High risk',
		[RiskScoreCategory.Medium]: 'Medium risk',
		[RiskScoreCategory.Low]: 'Low risk',
		[RiskScoreCategory.VeryLow]: 'Very low risk',
	},
	components: {
		transferList: {
			item: '1 item',
			items: '{{number}} items',
			searchPlaceholder: 'Search items',
		},
	},
	awarenessStatus: {
		[AwarenessStatus.InProgress]: 'Active',
		[AwarenessStatus.Upcoming]: 'Upcoming',
		[AwarenessStatus.Finished]: 'Finished',
	},
	noDataMessage: 'No data',
}

const en = {
	common,
	vulnerabilities,
	awareness,
	simulations,
	inventory,
	layout,
	organizations,
	settings,
	dashboard,
	admin,
	auth,
	waf,
	notifications,
	groupAdministrations,
	nanoLearn,
	library,
	leaks,
	extensionReports,
	publicPages,
	pdfExports,
}

export default en
