import { Suggestion, SuggestionActionTimeFrame } from 'blue-project-types'
import { FunctionComponent, forwardRef, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

// icons
import { ReactComponent as ClockIcon } from '../../../../assets/clock-fill.svg'
import { ReactComponent as RiskWarningIcon } from '../../../../assets/risk-warning.svg'
import { ReactComponent as SearchIcon } from '../../../../assets/search.svg'

import ContentLoader from '../../../../components/ContentLoader'
import { Colors } from '../../../../styles/colors'
import { SPACES } from '../../../../styles/spaces'
import TitleSubtitleWrapper from '../../../inventory/components/common/TitleSubtitleWrapper'
import { IRiskScoreSuggestion } from '../../../inventory/hooks/memo/useIdentifyRiskScoreSuggestions'
import ActionLinkComponent from './ActionLinkComponent'

interface IProps {
	riskScoreSuggestions: IRiskScoreSuggestion[]
	actionTimeFrame: SuggestionActionTimeFrame
}

const TRANSLATE_RECOMMENDED_ACTIONS_BASE_KEY =
	'pdfExports.RiskManagementExport.recommendedActions.list'

const ActionListCard = forwardRef<any, IProps>(function ActionListCard(props, ref) {
	const { actionTimeFrame, riskScoreSuggestions } = props

	const { t } = useTranslation()

	const [isLoading, setIsLoading] = useState<boolean>(false)

	const iconMapper = useCallback(
		(actionTimeFrame: SuggestionActionTimeFrame) => {
			switch (actionTimeFrame) {
				case SuggestionActionTimeFrame.Immediate:
					return <RiskWarningIcon />
				case SuggestionActionTimeFrame.ShortTerm:
					return <ClockIcon />
				case SuggestionActionTimeFrame.LongTerm:
					return <SearchIcon />
				default:
					throw new Error(`No icon mapped for action time-frame: ${actionTimeFrame}`)
			}
		},
		[actionTimeFrame],
	)

	const titleColorMapper = useCallback(
		(actionTimeFrame: SuggestionActionTimeFrame) => {
			switch (actionTimeFrame) {
				case SuggestionActionTimeFrame.Immediate:
					return Colors.RedNew
				case SuggestionActionTimeFrame.ShortTerm:
					return Colors.OrangeNew
				case SuggestionActionTimeFrame.LongTerm:
					return Colors.GreenNew
				default:
					throw new Error(`No color mapped for action time-frame: ${actionTimeFrame}`)
			}
		},
		[actionTimeFrame],
	)

	return (
		<Wrapper ref={ref}>
			<TitleWrapper>
				{iconMapper(actionTimeFrame)}
				<TitleSubtitleWrapper
					title={t(`${TRANSLATE_RECOMMENDED_ACTIONS_BASE_KEY}.title.${actionTimeFrame}`)}
					subtitle={t(`${TRANSLATE_RECOMMENDED_ACTIONS_BASE_KEY}.subtitle.${actionTimeFrame}`)}
					titleColor={titleColorMapper(actionTimeFrame)}
					widthPercentage={100}
				/>
			</TitleWrapper>
			{isLoading ? (
				<ContentLoader />
			) : (
				<>
					{riskScoreSuggestions.map((suggestion) => {
						return (
							<ActionLinkComponent
								key={suggestion.name}
								actionName={suggestion.name}
								actionTimeFrame={actionTimeFrame}
							/>
						)
					})}
				</>
			)}
		</Wrapper>
	)
})

const Wrapper = styled.section`
	display: flex;
	flex-direction: column;
	border-radius: ${SPACES.DEFAULT};
	background: #f1f3f7;
`
const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: ${SPACES.SMALL};
	padding: ${SPACES.EXTRA_LARGE} ${SPACES.DEFAULT};
`

export default ActionListCard
