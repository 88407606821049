import { RiskScoreCategory } from 'blue-project-types'
import {
	ShieldErrorIcon,
	ShieldSuccessIcon,
	ShieldWarningIcon,
} from '../../../../../components/Icons'
import { RiskScoreColors } from '../../../../../styles/colors'
import { SPACES } from '../../../../../styles/spaces'

interface IProps {
	riskCategory: RiskScoreCategory
}

const MAP_RISK_SCORE_TO_ICON = {
	[RiskScoreCategory.Critical]: ShieldErrorIcon,
	[RiskScoreCategory.High]: ShieldErrorIcon,
	[RiskScoreCategory.Medium]: ShieldWarningIcon,
	[RiskScoreCategory.Low]: ShieldSuccessIcon,
	[RiskScoreCategory.VeryLow]: ShieldSuccessIcon,
}

const RiskScoreSummaryIcon: React.FC<IProps> = ({ riskCategory }) => {
	const Component = MAP_RISK_SCORE_TO_ICON[riskCategory]

	return (
		<div style={{ marginTop: 6, marginRight: SPACES.SMALL }}>
			<Component style={{ fontSize: 48, color: RiskScoreColors[riskCategory] }} />
		</div>
	)
}

export default RiskScoreSummaryIcon
