import axios from 'axios'
import {
	ICreateSearch,
	ICredentialCredit,
	IUpdateLeakStatus,
	IReattemptFileParse,
} from 'blue-project-types'

import { API_LEAKS_URL } from '../../../config/urls'

export interface IUpdateLeakStatusInput {
	leakId: string
	data: IUpdateLeakStatus
}

export const FETCH_CREDENTIAL_SEARCHES_KEY = 'fetchCredentialSearches'
export const fetchCredentialSearches = async () => {
	const response = await axios.get(`${API_LEAKS_URL}/searches`)

	return response.data
}

export const FETCH_CREDENTIAL_SEARCH_KEY = 'fetchCredentialSearch'
export const fetchCredentialSearch = async (term: string) => {
	const response = await axios.get(`${API_LEAKS_URL}/searches/by-term`, { params: { term } })

	return response.data
}

export const FETCH_CREDENTIALS_BY_TERM_KEY = 'fetchCredentialsByTerm'
export const fetchCredentialsByTerm = async (term: string) => {
	const response = await axios.get(`${API_LEAKS_URL}/searches/leaks`, { params: { term } })

	return response.data
}

export const FETCH_FILES_BY_TERM_KEY = 'fetchFilesByTerm'
export const fetchFilesByTerm = async (term: string) => {
	const response = await axios.get(`${API_LEAKS_URL}/searches/files`, { params: { term } })

	return response.data
}

export const REATTEMPT_FILE_PARSE_KEY = 'reattemptFileParse'
export const reattemptFileParse = async (data: IReattemptFileParse) => {
	const response = await axios.post<void>(`${API_LEAKS_URL}/searches/files/reattemptParse`, data)

	return response.data
}

export const ADD_NEW_SEARCH_KEY = 'addNewSearch'
export const addNewSearch = async (data: ICreateSearch) => {
	const response = await axios.post(`${API_LEAKS_URL}/searches`, data)

	return response.data
}

export const CHANGE_LEAK_STATUS_KEY = 'changeLeakStatus'
export const changeLeakStatus = async ({ leakId, data }: IUpdateLeakStatusInput) => {
	const response = await axios.post(`${API_LEAKS_URL}/tenant-leaks/${leakId}/status`, data)

	return response.data
}

export const NOTIFY_LEAKED_CREDENTIALS_ALL = 'notifyLeakedCredentialAll'
//TODO: types
export const notifyLeakedCredentialAll = async (data) => {
	const response = await axios.post(
		`${API_LEAKS_URL}/credential-leaks-notifications/notify-all`,
		data,
	)

	return response.data
}

export const NOTIFY_LEAKED_CREDENTIALS_ONE = 'notifyLeakedCredentialOne'
export const notifyLeakedCredentialOne = async (data) => {
	const response = await axios.post(
		`${API_LEAKS_URL}/credential-leaks-notifications/notify-one`,
		data,
	)

	return response.data
}

export const FETCH_MY_DATA_LEAKS_KEY = 'fetchMyDataLeaks'
export const fetchMyDataLeaks = async () => {
	const response = await axios.get(`${API_LEAKS_URL}/tenant-leaks`)

	return response.data
}

export const FETCH_DOMAINS_KEY = 'fetchDomains'
export const fetchDomains = async () => {
	const response = await axios.get(`${API_LEAKS_URL}/tenant-domains`)

	return response.data
}

export const FETCH_ALL_DOMAINS_KEY = 'fetchAllDomains'
export const fetchAllDomains = async () => {
	const response = await axios.get(`${API_LEAKS_URL}/tenant-domains/all`)

	return response.data
}

export const FETCH_CREDENTIAL_CREDITS_KEY = 'fetchCredentialCredits'
export const fetchCredentialCredits = async (): Promise<ICredentialCredit[]> => {
	const response = await axios.get<ICredentialCredit[]>(`${API_LEAKS_URL}/credits/`)

	return response.data
}

export const FETCH_LEAKS_PEOPLE_SUMMARY_KEY = 'fetchLeaksPeopleSummary'
export const fetchLeaksPeopleSummary = async () => {
	const response = await axios.get(`${API_LEAKS_URL}/people-overview`)

	return response.data
}
