import { IApiError, IGetPeopleAwarenessOverviewResponse } from 'blue-project-types'
import { useQuery, useQueryClient } from 'react-query'

import { fetchPeopleAwarenessOverview, FETCH_PEOPLE_AWARENESS_OVERVIEW_KEY } from '../../utils/api'

const useFetchPeopleAwarenessOverview = () => {
	const queryClient = useQueryClient()
	const { data, isLoading, error } = useQuery<IGetPeopleAwarenessOverviewResponse, IApiError>(
		FETCH_PEOPLE_AWARENESS_OVERVIEW_KEY,
		() => fetchPeopleAwarenessOverview(),
	)

	const refetchPeopleAwarenessOverview = () => {
		queryClient.invalidateQueries([FETCH_PEOPLE_AWARENESS_OVERVIEW_KEY])
	}

	return {
		fetchingPeopleAwarenessOverviewError: error,
		peopleAwarenessOverview: data,
		isFetchingPeopleAwarenessOverview: isLoading,
		refetchPeopleAwarenessOverview,
	}
}

export default useFetchPeopleAwarenessOverview
