import styled from 'styled-components'

import { Title } from '../../../../../../components'
import { Colors } from '../../../../../../styles/colors'
import { SPACES } from '../../../../../../styles/spaces'

interface IProps {
	leaksCount: number
	description: string
	icon: React.ReactNode
	backgroundColor: string
}

const LeaksFrame: React.FC<IProps> = ({ leaksCount, description, icon, backgroundColor }) => {
	return (
		<Frame color={backgroundColor}>
			<div>
				{icon}
				<Title zeroMargin level={4} color={Colors.White}>
					{description}
				</Title>
				<span>{leaksCount}</span>
			</div>
		</Frame>
	)
}

const Frame = styled.div<{ color: string }>`
	background-color: ${({ color }) => color || 'transparent'};
	width: 100%;
	max-width: 30%;
	border-radius: ${SPACES.DEFAULT};
	padding: ${SPACES.DEFAULT};
	svg {
		margin-bottom: ${SPACES.SMALL};
	}
	span {
		font-size: ${SPACES.EXTRA_LARGE};
		font-weight: 600;
		color: ${Colors.White};
	}
	div {
		justify-items: center;
		padding: ${SPACES.DEFAULT};
		text-align: center;
	}
`
export default LeaksFrame
