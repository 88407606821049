import axios from 'axios'
import { ICreateSimulationRequest, ITargetTemplate } from 'blue-project-types'

import { API_LEARNING_URL, API_PHISHING_URL } from '../../../config/urls'
import { IRemoveLearnerData, ISendTesting } from './types'
import { ITemplateCreateForm } from '../../admin/utils/types'

export const CREATE_SIMULATION_KEY = 'createSimulation'
export const createSimulation = async (data: ICreateSimulationRequest) => {
	const response = await axios.post(`${API_PHISHING_URL}/simulations`, data)

	return response.data
}

export const FETCH_SIMULATIONS_KEY = 'fetchSimulations'
export const fetchSimulations = async () => {
	const response = await axios.get(`${API_PHISHING_URL}/simulations`)

	return response.data
}

export const FETCH_SIMULATION_KEY = 'fetchSimulation'
export const fetchSimulation = async (id: string) => {
	const response = await axios.get(`${API_PHISHING_URL}/simulations/${id}`)
	return response.data
}

export const FETCH_TARGET_KEY = 'fetchTarget'
export const fetchTarget = async ({
	simulationId,
	targetId,
}: {
	simulationId: string
	targetId: string
}) => {
	const response = await axios.get(
		`${API_PHISHING_URL}/simulations/${simulationId}/targets/${targetId}`,
	)
	return response.data
}

export const FETCH_SIMULATION_OVERVIEW_KEY = 'fetchSimulationOverview'
export const fetchSimulationOverview = async (id: string) => {
	const response = await axios.get(`${API_PHISHING_URL}/simulations/${id}/overview`)
	return response.data
}

export const FETCH_TARGETS_OVERVIEW_KEY = 'fetchTargetsOverview'
export const fetchTargetsOverview = async (id: string) => {
	const response = await axios.get(`${API_PHISHING_URL}/simulations/${id}/targets-overview`)

	return response.data
}

export const UPDATE_SIMULATION_STATUS_KEY = 'updateSimulationStatus'
export const updateSimulationStatus = async (simulationId: string, status: string) => {
	const response = await axios.put(
		`${API_PHISHING_URL}/simulations/${simulationId}/update-status`,
		{ status: status },
	)

	return response.data
}

// change bellow when api edit done
export const REMOVE_LEARNER_KEY = 'removeLearner'
export const removeLearner = async (data: IRemoveLearnerData) => {
	const response = await axios.delete(
		`${API_LEARNING_URL}/courses/${data.courseId}/learners/${data.learnerId}`,
	)

	return response.data
}

export const UPDATE_TARGET_STATUS_KEY = 'updateTargetStatus'
export const updateTargetStatus = async ({ simulationId, targetId, status }) => {
	const response = await axios.put(
		`${API_PHISHING_URL}/simulations/${simulationId}/targets/${targetId}/update-status`,
		{ status },
	)

	return response.data
}

export const FETCH_ORG_AVAILABLE_TEMPLATES_KEY = 'fetchOrgAvailableTemplates'
export const fetchOrgAvailableTemplates = async () => {
	const response = await axios.get(`${API_PHISHING_URL}/templates`)

	return response.data
}

export const SENT_TEST_TEMPLATES_KEY = 'sendEmails'
export const sendTestTemplate = async (data: ISendTesting) => {
	const response = await axios.post(`${API_PHISHING_URL}/templates/send-testing`, data)
	return response.data
}

export const SEND_TARGET_TEMPLATE_KEY = 'sendTargetTemplate'
export const sendTargetTemplate = async ({ id, simulationId }: ITargetTemplate) => {
	const response = await axios.post(
		`${API_PHISHING_URL}/target-simulation/${simulationId}/send-target-template/${id}`,
	)

	return response.data
}

export const COPY_TEMPLATE_KEY = 'copyTemplate'
export const copyTemplate = async (id: string) => {
	const response = await axios.put(`${API_PHISHING_URL}/templates/${id}/copy`)

	return response.data
}

export const EDIT_ORG_TEMPLATE_KEY = 'editOrgTemplate'
export const editOrgTemplate = async (id: string, data: ITemplateCreateForm) => {
	const response = await axios.put(
		`${API_PHISHING_URL}/templates/${id}/organization-template`,
		data,
	)

	return response.data
}

export const FETCH_PEOPLE_PHISHING_OVERVIEW_KEY = 'fetchPeoplePhishingOverview'
export const fetchPeoplePhishingOverview = async () => {
	const response = await axios.get(`${API_PHISHING_URL}/people-overview`)

	return response.data
}
