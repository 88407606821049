import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Alert, GlobalLoader } from '../../../components'
import Title from '../../../components/Title'
import { Colors } from '../../../styles/colors'
import { SPACES } from '../../../styles/spaces'
import useFetchPeopleAwarenessOverview from '../../awareness/hooks/api/useFetchPeopleAwarenessOverview'
import PeopleRiskScoreV2 from '../../inventory/components/people/risk-score/PeopleRiskScoreV2'
import useFetchPeopleSummary from '../../inventory/hooks/api/useFetchPeopleSummary'
import useFetchPeople from '../../inventory/hooks/useFetchPeople'
import useFetchLeaksPeopleSummary from '../../leaks/hooks/api/useFetchLeaksPeopleSummary'
import CampaignsOverview from '../../pdf-exports/components/CampaignsOverview'
import CredentialLeakMonitoring from '../../inventory/components/people/risk-score/credential-leaks/CredentialLeakMonitoring'
import RecommendedActions from '../../pdf-exports/components/recommended-actions/RecommendedActions'
import useFetchPeoplePhishingOverview from '../../simulations/hooks/api/useFetchPeoplePhishingOverview'

const DASHBOARD_BASE_KEY = 'dashboard.overview'

const OverviewV2 = () => {
	const { t } = useTranslation()

	const { people, isFetchingPeople } = useFetchPeople()

	const { peopleAwarenessOverview, isFetchingPeopleAwarenessOverview } =
		useFetchPeopleAwarenessOverview()

	const { peoplePhishingOverview, isFetchingPeoplePhishingOverview } =
		useFetchPeoplePhishingOverview()

	const { isFetchingPeopleSummary, peopleSummary } = useFetchPeopleSummary()

	const { leaksPeopleSummary = { leaks: [], domainsCount: 0, leaksResolvedThisMonth: 0 } } =
		useFetchLeaksPeopleSummary()

	const isLoading =
		isFetchingPeople ||
		isFetchingPeopleAwarenessOverview ||
		isFetchingPeoplePhishingOverview ||
		isFetchingPeopleSummary

	if (isLoading) {
		return <GlobalLoader />
	}

	if (!peopleSummary?.riskScore && !isLoading) {
		return (
			<Wrapper>
				<Alert
					title={t(`${DASHBOARD_BASE_KEY}.noRiskScoreAlert.title`)}
					subtitle={t(`${DASHBOARD_BASE_KEY}.noRiskScoreAlert.subtitle`)}
					type={'warning'}
				></Alert>
			</Wrapper>
		)
	}

	return (
		<Wrapper>
			<Title level={1} color={Colors.Dark} style={{ marginBottom: SPACES.DEFAULT }}>
				{t(`${DASHBOARD_BASE_KEY}.overviewTitle`)}
			</Title>
			<PeopleRiskScoreV2 peopleSummary={peopleSummary!} people={people} />
			<RecommendedActions peopleSummary={peopleSummary!} people={people} />
			<CampaignsOverview
				peoplePhishingOverview={peoplePhishingOverview!}
				peopleAwarenessOverview={peopleAwarenessOverview!}
			/>
			<CredentialLeakMonitoring leaksPeopleSummary={leaksPeopleSummary} />
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	background: ${Colors.BackgroundGray};
`

export default OverviewV2
