import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SuggestionActionTimeFrame } from 'blue-project-types'

import { Button } from '../../../../components'
import { SPACES } from '../../../../styles/spaces'
import TitleSubtitleWrapper from '../../../inventory/components/common/TitleSubtitleWrapper'
import { useHistory } from 'react-router-dom'

interface IProps {
	actionName: string
	actionTimeFrame: SuggestionActionTimeFrame
}

const TRANSLATE_RECOMMENDED_ACTIONS_LINK_COMPONENT_BASE_KEY =
	'pdfExports.RiskManagementExport.recommendedActions.list.action'

const ActionLinkComponent: React.FC<IProps> = ({ actionName, actionTimeFrame }) => {
	const { t } = useTranslation()
	const history = useHistory()

	const navigationLink = `${window.location.origin}/?actionTimeFrame=${actionTimeFrame}`

	return (
		<ActionWrapper>
			<TitleSubtitleWrapper
				smallerFontTitle={true}
				title={t(`${TRANSLATE_RECOMMENDED_ACTIONS_LINK_COMPONENT_BASE_KEY}.${actionName}.title`)}
				subtitle={t(
					`${TRANSLATE_RECOMMENDED_ACTIONS_LINK_COMPONENT_BASE_KEY}.${actionName}.subtitle`,
				)}
			/>
			<Button type="primary">
				<a href={navigationLink}>
					{t(`${TRANSLATE_RECOMMENDED_ACTIONS_LINK_COMPONENT_BASE_KEY}.button`)}
				</a>
			</Button>
		</ActionWrapper>
	)
}

const ActionWrapper = styled.section`
	display: flex;
	align-items: center;
	padding: ${SPACES.SMALL} ${SPACES.DEFAULT};
	justify-content: space-between;
`
export default ActionLinkComponent
