import styled from 'styled-components'
import { Button } from 'antd'

import { Colors } from '../../../../styles/colors'
import { SPACES } from '../../../../styles/spaces'
import Text from '../../../../components/Text'

export const DetailsSection = styled.div`
	background: ${Colors.White};
	padding: ${SPACES.SMALL};
	width: 100%;
	border-radius: 6px;
`

export const PropertySection = styled.div`
	margin-bottom: ${SPACES.SMALL};
	display: flex;
	&:last-child {
		margin-bottom: 0;
	}
`

export const PropertyScore = styled.span<{ score: number }>`
	font-size: 24px;
	font-weight: 600;
	min-width: 50px;
	margin-right: ${SPACES.EXTRA_SMALL};
	color: ${({ score }) => (score > 0 ? Colors.Red : Colors.Green)};
`

export const SuggestionButton = styled(Button)`
	&&& {
		border: 1px solid ${Colors.Blue};
		margin: 0 ${SPACES.SMALL} ${SPACES.SMALL} 0;
	}
`
export const Description = styled(Text)`
	font-size: ${SPACES.SMALL};
	padding-top: ${SPACES.SMALL};
	font-weight: 400;
	color: ${Colors.Gray};
`
