import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { RiskScoreCategory } from 'blue-project-types'

import { Text } from '../../../../../components'
import { Colors } from '../../../../../styles/colors'
import { SPACES } from '../../../../../styles/spaces'

interface IProps {
	riskCategory: RiskScoreCategory
}

const PEOPLE_RISK_SCORE_BASE_TRANSLATE = 'inventory.people.riskScore.tenantOverview'

const RiskScoreSummaryTextV2: React.FC<IProps> = ({ riskCategory }) => {
	const { t } = useTranslation()

	return (
		<TextWrapper>
			<Text zeroMargin color={Colors.Dark}>
				{t(`${PEOPLE_RISK_SCORE_BASE_TRANSLATE}.riskScoreText.${riskCategory}`)}
			</Text>
			<Text zeroMargin color={Colors.Dark}>
				{t(`${PEOPLE_RISK_SCORE_BASE_TRANSLATE}.summaryText.${riskCategory}`)}
			</Text>
		</TextWrapper>
	)
}

const TextWrapper = styled.div`
	font-size: 24px;
	display: flex;
	flex-direction: column;
	font-weight: 500;
	max-width: 680px;
	gap: ${SPACES.DEFAULT};
	padding-left: ${SPACES.DEFAULT};
`

export default RiskScoreSummaryTextV2
