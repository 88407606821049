const sr = {
	overview: {
		title: 'Pregled kontinuirane bezbednosti',
		header: {
			riskScore: 'Procena rizika',
		},
		noRiskScoreAlert: {
			title: 'Procena rizika nije dostupna',
			subtitle:
				'Procena rizika  za ovu organizaciju nije izvršena, ili je došlo do greške prilikom preuzimanja. Molimo vas da pokušate ponovo kasnije ili kontaktirajte podršku za pomoć.',
		},
		activeOrg: {
			title: 'Analitike organizacije',
			organization: 'Organizacija',
			organizations: 'Broj organizacija',
			organizationDescription: 'pod kontinuiranim nadzorom',
			people: 'Broj zaposlenih u {{tenantName}} organizaciji',
			peopleDescription: 'Zaposleni u aktivnoj organizaciji',
			assignments: 'Aktivni zadaci',
			assignment: 'Aktivni zadatak',
			assignmentsDescription: 'Aktivan zadatak u toku',
			assets: 'Resursi u {{tenantName}} organizaciji',
			asset: 'Broj resursa u {{tenantName}} organizaciji',
			assetsDescription: 'Resursi u aktivnoj organizaciji',
			learners: 'Polazinici u obuci',
			learnersDescription: 'Polaznici koji su započeli kurs',
		},
		activeAssignments: {
			title: 'Analitika zadataka',
			completionRate: 'Stopa završenosti kursa',
			completionRateDescription: 'Napredak svih polaznika',
			learnerCompletion: 'learner completion', // TO DO
			learnerCompletionDescription:
				'odnos polaznika koji su završili kurs naspram onih koji još nisu',
			passingRate: 'stopa prolaznosti',
			passingRateDescription: 'odnos polaznika koji su položili naspram onih koji su pali',
			averageScore: 'Prosečni rezultat',
			averageScoreDescription: 'polaznika koji su položili kurs',
		},
		learningAssignments: {
			title: 'Zadaci za polaznike',
		},
		vulnerabilities: {
			title: "Vulnerability's Analytics",
		},
		quickLinks: {
			title: 'Brzi linkovi',
			addPeople: '+ Dodajte polaznike',
			addNewOrganization: '+ Dodajte novu organizaciju',
			createNewAssignment: '+ Kreirajte zadatak iz informacione bezbednosti',
			addNewUsers: '+ Dodajte nove korisnike',
		},
		noAssets: {
			title: 'Nepostoje resursi unutar organizacije',
			subtitle: 'Molimo dodajte resurse kako biste mogli videti pregled resursa',
		},
	},
}

export default sr
