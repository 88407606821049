import { IPeopleOverviewResponse, IPerson, SuggestionActionTimeFrame } from 'blue-project-types'
import { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Colors } from '../../../../styles/colors'
import { SPACES } from '../../../../styles/spaces'
import TitleSubtitleWrapper from '../../../inventory/components/common/TitleSubtitleWrapper'
import useIdentifyRiskScoreSuggestions, {
	IRiskScoreSuggestion,
} from '../../../inventory/hooks/memo/useIdentifyRiskScoreSuggestions'
import ActionListCard from './ActionListCard'
import { useLocation } from 'react-router-dom'

interface IProps {
	peopleSummary: IPeopleOverviewResponse
	people: IPerson[]
}

const TRANSLATE_RECOMMENDED_ACTIONS_BASE_KEY = 'pdfExports.RiskManagementExport.recommendedActions'

const RecommendedActions: React.FC<IProps> = ({ people, peopleSummary }) => {
	const { t } = useTranslation()
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)

	const scrollToActionTimeFrame = queryParams.get('actionTimeFrame')

	const actionRef = useRef<HTMLElement | null>(null)

	useEffect(() => {
		if (actionRef?.current) {
			actionRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
		}
	}, [actionRef])

	const riskScoreSuggestions = useIdentifyRiskScoreSuggestions({ peopleSummary, people })

	const riskScoreActionTimeFrameGroups = useMemo(() => {
		return riskScoreSuggestions.reduce<Record<SuggestionActionTimeFrame, IRiskScoreSuggestion[]>>(
			(acc, suggestion) => {
				const { actionTimeFrame } = suggestion.meta
				if (!acc[actionTimeFrame]) {
					acc[actionTimeFrame] = []
				}
				acc[actionTimeFrame].push(suggestion)
				return acc
			},
			{
				[SuggestionActionTimeFrame.Immediate]: [],
				[SuggestionActionTimeFrame.ShortTerm]: [],
				[SuggestionActionTimeFrame.LongTerm]: [],
			},
		)
	}, [riskScoreSuggestions])

	return (
		<Wrapper>
			<div style={{ paddingBlock: '16px', marginBottom: '16px' }}>
				<TitleSubtitleWrapper
					title={t(`${TRANSLATE_RECOMMENDED_ACTIONS_BASE_KEY}.title`)}
					subtitle={t(
						riskScoreSuggestions.length
							? `${TRANSLATE_RECOMMENDED_ACTIONS_BASE_KEY}.subtitle`
							: `${TRANSLATE_RECOMMENDED_ACTIONS_BASE_KEY}.subtitleNoActions`,
					)}
				/>
			</div>
			{Object.entries(riskScoreActionTimeFrameGroups).map(([timeFrame, groupedSuggestions]) => {
				if (groupedSuggestions.length === 0) {
					return
				}
				return (
					<ActionListCard
						ref={
							scrollToActionTimeFrame?.toLocaleLowerCase() === timeFrame.toLocaleLowerCase()
								? actionRef
								: null
						}
						key={timeFrame}
						riskScoreSuggestions={groupedSuggestions}
						actionTimeFrame={timeFrame as SuggestionActionTimeFrame}
					/>
				)
			})}
		</Wrapper>
	)
}

const Wrapper = styled.section`
	display: flex;
	flex-direction: column;
	gap: ${SPACES.DEFAULT};
	background: ${Colors.White};
	border-bottom-left-radius: ${SPACES.DEFAULT};
	border-bottom-right-radius: ${SPACES.DEFAULT};
	padding: ${SPACES.EXTRA_LARGE} 48px;
`
export default RecommendedActions
