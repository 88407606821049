import {
	AssetType,
	DiscoverType,
	RecordType,
	RiskScoreCategory,
	RiskScoreProperty,
} from 'blue-project-types'

const en = {
	people: {
		common: {
			addPeople: {
				title: 'Enter people details',
				subtitle: 'Chose the method to import people data',
				social: {
					importFrom: 'Import from',
					googleButton: 'Connect data with Google',
					googleFailed: 'Sync with google failed',
					microsoftButton: 'Connect data with Microsoft',
					microsoftFailed: 'Sync with microsoft failed',
				},
				csv: {
					buttonMainText: 'Upload CSV file',
					buttonSecondaryText: "Drag'n'drop or click to add from computer",
					subtitle: 'Mark or confirm data from CSV file',
					smallDescription1:
						'We need your help to identify names, surnames and emails in the uploaded file.',
					smallDescription2: 'Choose the column that contains user data from dropdown menu.',
					notGoodMatch: 'Not good match',
					propertyNotExists: 'Not exists',
					example: 'Find CSV example <1>here</1>',
				},
				errorCsv: 'Your uploaded Csv file is not valid.',
				manually: {
					button: 'Add employees manually',
					smallDescription: 'For small organizations or test purposes',
					person: 'Person',
					addNextButton: 'Add next person',
				},
				properties: {
					firstName: {
						label: 'First name',
						placeholder: 'Enter first name',
					},
					lastName: {
						label: 'Last name',
						placeholder: 'Enter last name',
					},
					email: {
						label: 'Email',
						placeholder: 'Enter email',
					},
					groups: {
						label: 'Groups',
						placeholder: 'Enter group',
					},
				},
				actions: {
					peopleAddedSuccess: 'People successfully saved!',
					noPeopleToBeSaved: 'No people to be saved',
				},
				organizationMustBeSet: 'Organization must be set!',
			},
		},
		dashboard: {
			title: 'People',
			addNewButton: '+ Add People',
			search: {
				placeholder: 'Search for specific person',
			},
			table: {
				columns: {
					firstName: 'First name',
					lastName: 'Last name',
					email: 'Email',
					groups: 'Groups',
					createdAt: 'Date added',
					riskScore: 'Risk score',
				},
				actions: {
					edit: 'Edit',
					remove: 'Remove',
				},
				assetNoInfo: 'No information found for this asset',
			},
			filters: {
				groups: {
					name: 'Groups',
				},
			},
			removeModal: {
				title: 'Remove this person?',
				text: 'Are you sure you want to remove <1>{{fullName}}</1> from the inventory?',
			},
			editModal: {
				title: 'Edit person',
			},
		},
		overview: {
			title: "People's Analytics",
			noPeople: {
				title: 'There are no people within the organization',
				subtitle: 'Please add people to the organization to be able to see people overview',
			},
			alerts: {
				title: 'Alerts',
				seeAll: 'See all',
				seeLess: 'See less',
				noAlerts: {
					title: 'No alerts',
					subtitle: 'There are no alerts at the moment.',
				},
				courseNotCompleted: {
					title: 'Awareness course not completed - {{courseName}}',
					subtitle: "{{learnersCount}} people haven't completed before due date",
				},
				courseFailed: {
					title: 'Awareness course failed - {{courseName}}',
					subtitle: "{{learnersCount}} people haven't passed course",
				},
				simulationFailed: {
					title: 'Simulation failed - {{courseName}}',
					subtitle: '{{targetsCount}} people failed phishing simulation',
				},
				leaks: {
					title: 'Credential leak found',
					subtitle: 'Credential leak associated with {{username}} was exposed at {{exposedAt}}',
				},
			},
			statistics: {
				people: {
					title: 'People total',
					subtitle: 'Total number of employees within organization',
				},
				courses: {
					title: 'Courses total',
					subtitle: 'Total number of courses created',
				},
				simulations: {
					title: 'Phishing simulations total',
					subtitle: 'Total number of phishing simulations created',
				},
				nanoCampaigns: {
					title: 'Nano campaigns total',
					subtitle: 'Total number of nano campaigns created',
				},
				domains: {
					title: 'Scanned domains',
					subtitle: 'Total number of domains scanned',
				},
			},
			dailyActivitySummary: {
				title: 'Daily activity summary',
				peopleOnCourse: '{{peopleOnCourse}} active people on course today',
				peoplePassed: '{{peoplePassed}} people passed a course today',
				peopleFailed: '{{peopleFailed}} people failed a course today',
				attacksSent: '{{attacksSent}} phishing templates sent today',
				targetsFailed: '{{targetsFailed}} targets failed on a simulation today',
				targetsPassed: '{{targetsPassed}} targets successfully reported phishing attempt today',
			},
			failedTargetsPerTemplate: {
				title: 'Failed targets per template',
			},
			nanoLearning: {
				title: 'Nano learning',
				lessonsSent: 'Lessons sent',
			},
			awareness: {
				title: 'Awareness',
			},
			nanoLearningStatisticsChart: {
				answeredCorrectlyCount: 'Passed',
				nanoLessonsRepeatedCount: 'Retried',
				incorrectAnswerCount: 'Failed',
			},
			recentActivity: {
				title: 'Recent activity',
			},
			people: {
				title: 'List of employees',
			},
			dailyActivityChart: {
				failedLabel: 'Failed targets',
				openedLabel: 'Opened templates',
				sentLabel: 'Sent templates',
				sentContentLabel: 'Sent content',
				engagedLabel: 'Engaged in content',
				passedLearnersLabel: 'Passed learners',
				failedLearnersLabel: 'Failed learners',
			},
		},
		riskScore: {
			tenantOverview: {
				riskScoreTitle: 'Risk score',
				riskScoreDescription: `The meter shows your organization's current risk score, considering the results of training progress and credential leak control. Based on these findings, areas for improvement are highlighted.`,
				riskScoreDescriptionTitle: 'Description',
				riskScoreSummary: 'Risk Score summary',
				riskScoreVsLastMonth: '{{percent}}% vs last month',
				riskScoreText: {
					[RiskScoreCategory.Critical]:
						'Your current Risk Score is 76-100 points, categorized as Critical.',
					[RiskScoreCategory.High]: 'Your current Risk Score is 51-75 points, categorized as High.',
					[RiskScoreCategory.Medium]:
						'Your current Risk Score is 26-50 points, categorized as Medium.',
					[RiskScoreCategory.Low]: 'Your current Risk Score is 5-25, categorized as Low.',
					[RiskScoreCategory.VeryLow]: '',
				},
				summaryText: {
					[RiskScoreCategory.Critical]:
						'We suggest you take urgent action to improve employee security posture further to reduce your Risk Score.',
					[RiskScoreCategory.High]:
						'We suggest you take pressing action to improve employee security posture further to reduce your Risk Score.',
					[RiskScoreCategory.Medium]:
						'We suggest you take immediate action to improve employee security posture further to reduce your Risk Score.',
					[RiskScoreCategory.Low]: `To further reduce your Risk Score, we recommend implementing timely measures to enhance your organization's employee security posture.`,
					[RiskScoreCategory.VeryLow]:
						'Congratulations! Your organization has achieved a very low risk score, indicating that your employees are well-educated and ready to identify and address security issues swiftly.',
				},
				summary: {
					[RiskScoreCategory.Critical]:
						'Your organization has received a critical risk score, falling below 60, highlighting a pressing need for immediate action to enhance employee education and readiness in addressing security concerns.',
					[RiskScoreCategory.High]:
						'Your Risk Score is 63 points, ranking it high. We suggest you take immediate action to further improve employee security posture to reduce your Risk Score.',
					[RiskScoreCategory.Medium]:
						'Your organization has achieved a solid risk score, signifying a good level of education and readiness among your employees to address security concerns',
					[RiskScoreCategory.Low]:
						'Your organization has achieved a commendable risk score, reflecting the high level of education and readiness of your employees to address security concerns.',
					[RiskScoreCategory.VeryLow]:
						'Congratulations! Your organization has achieved a high risk score, indicating that your employees are well-educated and ready to swiftly identify and address security issues.',
				},
			},
			riskScoreProperties: {
				[RiskScoreProperty.Courses]: 'Assignments',
				[RiskScoreProperty.Leaks]: 'Credential leaks',
				[RiskScoreProperty.NanoLearning]: 'Nano learning',
				[RiskScoreProperty.Phishing]: 'Phishing simulations',
			},
			riskScorePropertiesMessages: {
				credentialLeaksNotNotified: 'credential leak(s) requiring immediate action',
				credentialLeaksNotified: 'credential leak(s) waiting to be resolved by employee',
				noAssignments: "Person isn't invited to any course",
				noCompletedAssignments: "Person is invited to the course but hasn't completed yet",
				failedAssignments: "Person hasn't successfully completed course(s):",
				expiredAssignments: "Parson hasn't completed course(s) before due date:",
				badLessons: 'Parson successfully completed course(s) but has lesson(s) to improve:',
				noPhishingSimulations: "Person isn't part of any phishing simulations",
				phishingSimulationsFailed: 'Person has failed phishing simulation(s):',
				nanoLearningEngageRate: 'Nano learning engage rate is:',
				perfectRiskScore: 'Nothing to be shown here, {{firstName}} has perfect risk score',
			},
			suggestions: {
				expiredCourseSuggestions: {
					courseCreated: 'Course successfully created',
					expireDateExtended: 'Learners expiring date extended successfully',
					noAction: 'No action specified',
					tooltip: `{{numOfLearners}} learners haven't completed "{{courseName}}". Create the course with same lessons only for learners that haven't completed or add more days to existing course to improve risk score`,
					button: `Extend or Recreate {{courseName}} for learners that haven't completed`,
					modalTitle: `Extend or Recreate {{courseName}} for learners that haven't completed`,
					modalActionsRecreate: 'Recreate new course',
					modalActionExtend: 'Add 7 more days',
				},
				improveLessonSuggestion: {
					tooltip: `{{numOfLearners}} need to improve "{{lessonName}}" knowledge. Create course with only "{{lessonName}}" lesson to improve risk score`,
					button: `Improve {{lessonName}}`,
				},
				noCourseExistsSuggestion: {
					tooltip: `{{numOfLearners}} aren't invited to any course. Create course and invite them to improve risk score`,
					button: 'Create first course',
				},
				noSimulationExistsSuggestion: {
					tooltip: `{{numOfLearners}} aren't part of any simulation. Create first simulation to improve risk score`,
					button: 'Create first simulation',
				},
				notifyPeopleAboutLeak: {
					tooltip: `Credential leaks found for {{numOfLearners}} people. Notify them so they can change passwords and improve risk score`,
					button: 'Notify people about credential leaks',
				},
				recreateFailedCourse: {
					tooltip: `{{numOfLearners}} have failed "{{courseName}}". Create the course with same lessons only for failed learners to improve risk score`,
					button: `Recreate {{courseName}} for failed learners`,
					confirmBtn: `Are you sure you want to create the course with the same lessons as "{{courseName}}" for:`,
				},
			},
		},
	},
	assets: {
		common: {
			addAssets: {
				title: 'Enter domains & IPs',
				subtitle: '',
				properties: {
					domainOrIp: {
						label: 'Domains or IPs',
						placeholder: 'Enter domain or IP',
					},
				},
				noAssetsToBeAdded: 'No assets to be added',
				assetAdded: '1 asset added',
				assetsAdded: '{{numberOfAssets}} assets added',
				organizationMustBeSet: 'Organization must be set!',
			},
			verifyAsset: {
				title: 'Verify',
				subtitle: `Verify your added assets by choosing one of the two options below, either as a TXT document or as DNS.`,
				tabs: {
					uploadTxtFile: 'Upload TXT-file',
					dnsTxtRecord: 'Add dns txt record',
				},
				buttonSubmit: 'Let’s see if it works!',
				uploadTxtFile: {
					item1: {
						title: 'Download the verification file',
						button: 'Download the verification file',
					},
					item2: {
						title1: `Place the downloaded file in the root of your asset.`,
						title2: `Need help with this? Try our {{ name }} article.`,
						title3: `We will try to access the file at:`,
					},
					successMsg: 'is now verifed',
					errorMsg: 'unable to verify',
				},
				dnsTxtRecord: {
					item1: {
						title: 'Go to your DNS provider settings.',
					},
					item2: {
						title1: `Insert the below text as a TXT record in your DNS.`,
						title2: `Unsure how to do this? Try our {{ name }} article.`,
					},
					record: {
						name: 'Record name',
						copy: 'Copy record name',
						copied: 'Copied',
					},
					value: {
						name: 'Value',
						copy: 'Copy value',
						copied: 'Copied',
					},
					infoMsg: 'Note that this can take up to 48 hours for changes to take affect',
				},
			},
			assetType: {
				[AssetType.DOMAIN]: 'Domain',
				[AssetType.IP_ADDRESS_V4]: 'IP Address v4',
				[AssetType.IP_ADDRESS_V6]: 'IP Address v6',
				[AssetType.SUBDOMAIN]: 'Subdomain',
			},
			discovered: {
				[DiscoverType.Manually]: 'Manually added',
				[DiscoverType.Automatically]: 'Automatically',
			},
			resolvingAssets: {
				notificationMessage: 'Resolving subdomains and IPs',
				completedNotificationMessagePlural: '{{numberOfAssets}} new assets resolved',
				completedNotificationMessageSingular: '1 new asset resolved',
				noNewAssetsNotificationMessage: 'No new assets discovered',
				refetchButton: 'Refetch',
				newDiscoverySubtitle:
					'New assets were found during last asset discovery process. Close alert clicking on got it button.',
				gotItButton: 'Got it',
				errorMessage: 'Error while resolving {{asset}} subdomains and IPs',
			},
		},
		dashboard: {
			title: 'Assets',
			addNewButton: '+ Add Asset',
			search: {
				placeholder: 'Search for specific asset',
			},
			noVulnerabilityFound: 'No vulnerabilities found',
			table: {
				columns: {
					asset: 'Asset',
					type: 'Type',
					discovery: 'Discovery',
					createdAt: 'Date added',
					vulnerabilityOverview: 'Vulnerability overview',
					monitoring: 'Monitoring',
				},
				actions: {
					viewVulnerabilities: 'View Vulnerabilities',
					remove: 'Remove',
					verify: 'Verify',
					assetNotAbleToTrack: {
						assetNotVerified: 'Must verify asset first',
						featureNotEnabled: 'Vulnerability feature is not enabled for your organization',
						notAllowed: "You don't have permission for this action",
					},
				},
			},
			removeModal: {
				title: 'Remove this asset?',
				text: 'Are you sure you want to remove <1>{{name}}</1> from the inventory?',
			},
			monitorToggleModal: {
				startTitle: 'Start monitoring this asset?',
				stopTitle: 'Stop monitoring this asset?',
				startText: 'Are you sure you want to start monitoring <1>{{name}}</1> asset?',
				stopText: 'Are you sure you want to stop monitoring <1>{{name}}</1> asset?',
			},
		},
		details: {
			headerInfo: {
				createdAt: 'Created at',
				discovered: 'Discovered',
			},
			ip: {
				tabs: {
					overview: 'OVERVIEW',
					history: 'HISTORIC DATA',
				},
				openPorts: 'Open Ports',
				ipDetails: {
					city: 'City',
					country: 'Country',
					organization: 'Organization',
					isp: 'ISP',
					lastUpdate: 'Last Update',
				},
				historyTable: {
					columns: {
						openPort: 'Open port',
						transport: 'Transport',
						protocol: 'Protocol',
						details: 'Details',
						firstSeen: 'First Seen',
						lastSeen: 'Last Seen',
					},
				},
				notLoadedYetAlert: {
					title: "IP address info hasn't been discovered yet",
					subtitle: "The data hasn't been discovered yet or error happened.",
				},
			},
			domain: {
				tabs: {
					dnsRecords: 'DNS RECORDS',
					history: 'HISTORIC DATA',
				},
				historyTable: {
					columns: {
						recordTypeToColumnName: {
							[RecordType.A]: 'IP Addresses',
							[RecordType.AAAA]: 'IP Addresses',
							[RecordType.MX]: 'Mail Servers',
							[RecordType.NS]: 'Name Servers',
							[RecordType.SOA]: 'SOA Records',
							[RecordType.TXT]: 'TXT Values',
						},
						organization: 'Organization',
						firstSeen: 'First Seen',
						lastSeen: 'Last Seen',
						durationSeen: 'Duration seen',
					},
				},
			},
		},
	},
}

export default en
