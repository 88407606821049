import styled from 'styled-components'
import { Spin } from 'antd'

const LoaderOverlay = styled.div`
  display: flex;
  self-align:center;
  justify-self:center
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(2px);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface IProps {
	isLoading?: boolean
	isInsideSidebar?: boolean
}

const ContentLoader: React.FC<IProps> = ({ isLoading = true, isInsideSidebar = true }) => {
	if (!isLoading) return null

	return (
		<LoaderOverlay style={{ width: isInsideSidebar ? '80wv' : '100%' }}>
			<Spin size="large" />
		</LoaderOverlay>
	)
}

export default ContentLoader
