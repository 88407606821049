import { RiskScoreCategory } from 'blue-project-types'

export enum Colors {
	Black = '#001433',
	BlackLight = '#222740',
	Dark = '#263767',
	DarkDest = '#384466',
	Blue = '#1581FF',
	BlueNew = '#1890FF',
	Gray = '#808FAD',
	GrayNew = '#F1F3F7',
	Light = '#D2D8E0',
	GrayHover = '#E1E5EB',
	GrayLighter = '#EDEFF2',
	GrayDark = '#d3d8e0',
	BackgroundGray = '#F0F3F7',
	White = '#FFF',
	Red = '#FF3148',
	RedNew = '#EB474F',
	RedLight = '#ffebee',
	RedDark = '#FC5164',
	HoverableRed = '#E82137',
	Yellow = '#FFE628',
	YellowLight = '#FEDEC3',
	Orange = '#FF7600',
	OrangeLight = '#fff2e8',
	OrangeNew = '#FB7604',
	Green = '#23C7B6',
	GreenNew = '#25C7B6',
	GreenDark = '#0C6A60',
	Violet = '#AF5FFF',
	VioletLight = '#9195c1',
	Error = '#ff586c',
	AntBorderGray = '#d9d9d9',
	BlueLight = '#A7D5FF',
}

export const RiskScoreColors = {
	[RiskScoreCategory.Critical]: '#e8474f',
	[RiskScoreCategory.High]: '#ff7600',
	[RiskScoreCategory.Medium]: '#f7c644',
	[RiskScoreCategory.Low]: '#4cd3bf',
	[RiskScoreCategory.VeryLow]: '#3CA898',
}
