import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Progress } from 'antd'

import Text from '../../../../components/Text'
import { Colors } from '../../../../styles/colors'
import { SPACES } from '../../../../styles/spaces'
import TitleSubtitleWrapper from '../../../inventory/components/common/TitleSubtitleWrapper'
import Title from '../../../../components/Title'

interface IProps {
	totalAttacks: number
	opened: number
	clicked: number
	failed: number
}

const TRANSLATE_SIMULATIONS_OVERVIEW_BASE_KEY =
	'pdfExports.RiskManagementExport.trainingCampaignsOverview'

const SimulationsResult: React.FC<IProps> = ({ totalAttacks, opened, clicked, failed }) => {
	const { t } = useTranslation()

	return (
		<Wrapper>
			<TitleSubtitleWrapper
				title={t(`${TRANSLATE_SIMULATIONS_OVERVIEW_BASE_KEY}.simulations.title`)}
				subtitle={t(`${TRANSLATE_SIMULATIONS_OVERVIEW_BASE_KEY}.simulations.subtitle`)}
			/>
			<ProgressWrapper>
				<Progress
					type="circle"
					percent={100}
					format={() => (
						<>
							<Text
								color={Colors.Black}
								zeroMargin
								style={{ fontSize: 14, fontWeight: 400, marginBottom: SPACES.EXTRA_SMALL }}
							>
								{t(`${TRANSLATE_SIMULATIONS_OVERVIEW_BASE_KEY}.simulations.progress.total`)}
							</Text>
							<Title color={Colors.Black} zeroMargin>
								{totalAttacks}
							</Title>
						</>
					)}
					strokeColor={Colors.BlueNew}
					strokeWidth={8}
					width={144}
				/>
				<Progress
					type="circle"
					percent={Math.round((opened / totalAttacks) * 100)}
					format={() => (
						<>
							<Text
								color={Colors.Black}
								zeroMargin
								style={{ fontSize: 14, fontWeight: 400, marginBottom: SPACES.EXTRA_SMALL }}
							>
								{t(`${TRANSLATE_SIMULATIONS_OVERVIEW_BASE_KEY}.simulations.progress.opened`)}
							</Text>
							<Title color={Colors.Black} zeroMargin>
								{Math.round((opened / totalAttacks) * 100)}%
							</Title>
						</>
					)}
					strokeColor={Colors.OrangeNew}
					strokeWidth={8}
					width={144}
				/>
				<Progress
					type="circle"
					percent={Math.round((clicked / totalAttacks) * 100)}
					format={() => (
						<>
							<Text
								color={Colors.Black}
								zeroMargin
								style={{ fontSize: 14, fontWeight: 400, marginBottom: SPACES.EXTRA_SMALL }}
							>
								{t(`${TRANSLATE_SIMULATIONS_OVERVIEW_BASE_KEY}.simulations.progress.clicked`)}
							</Text>
							<Title color={Colors.Black} zeroMargin>
								{Math.round((clicked / totalAttacks) * 100)}%
							</Title>
						</>
					)}
					strokeColor={Colors.GreenNew}
					strokeWidth={8}
					width={144}
				/>
				<Progress
					type="circle"
					percent={Math.round((failed / totalAttacks) * 100)}
					format={() => (
						<>
							<Text
								color={Colors.Black}
								zeroMargin
								style={{ fontSize: 14, fontWeight: 400, marginBottom: SPACES.EXTRA_SMALL }}
							>
								{t(`${TRANSLATE_SIMULATIONS_OVERVIEW_BASE_KEY}.simulations.progress.failed`)}
							</Text>
							<Title color={Colors.Black} zeroMargin>
								{Math.round((failed / totalAttacks) * 100)}%
							</Title>
						</>
					)}
					strokeColor={Colors.RedNew}
					strokeWidth={8}
					width={144}
				/>
			</ProgressWrapper>
		</Wrapper>
	)
}

const Wrapper = styled.section`
	display: flex;
	flex-direction: column;
	padding: 40px 48px;
	background: ${Colors.White};
	border-bottom-left-radius: ${SPACES.DEFAULT};
	border-bottom-right-radius: ${SPACES.DEFAULT};
`
const ProgressWrapper = styled.div`
	display: flex;
	margin-top: ${SPACES.DEFAULT};
	justify-content: space-between;
	height: 144px;
`
export default SimulationsResult
