import { IPeopleOverviewResponse, IPerson, getRiskScoreCategory } from 'blue-project-types'
import { useMemo } from 'react'
import styled from 'styled-components'

import { Colors } from '../../../../../styles/colors'
import { SPACES } from '../../../../../styles/spaces'
import { calculateRiskScoreChange } from '../../../utils/risk-score'
import RiskScoreSummaryV2 from './RiskScoreSummaryV2'
import RiskScoreValueV2 from './RiskScoreValueV2'

interface IProps {
	peopleSummary: IPeopleOverviewResponse
	people: IPerson[]
}

const PeopleRiskScoreV2: React.FC<IProps> = ({ peopleSummary, people }) => {
	const changePercentage = useMemo(() => {
		if (!peopleSummary) {
			return
		}
		return calculateRiskScoreChange(peopleSummary.lastMonthsRiskScore || 0, peopleSummary.riskScore)
	}, [peopleSummary?.riskScore, peopleSummary?.lastMonthsRiskScore])

	const riskCategory = useMemo(() => {
		if (!peopleSummary) return null
		return getRiskScoreCategory(peopleSummary.riskScore)
	}, [peopleSummary])

	return (
		<Wrapper>
			<RiskScoreValueV2
				riskScore={peopleSummary.riskScore}
				riskScoreCategory={riskCategory!}
				changePercentage={changePercentage}
			/>
			<RiskScoreSummaryV2 riskCategory={riskCategory!} />
		</Wrapper>
	)
}

const Wrapper = styled.section`
	display: flex;
	gap: ${SPACES.DEFAULT};
	background: ${Colors.White};
	border-top-left-radius: ${SPACES.DEFAULT};
	border-top-right-radius: ${SPACES.DEFAULT};
`

export default PeopleRiskScoreV2
