import { IGetPeopleLeaksOverview, LeakStatus } from 'blue-project-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

// icons
import { ReactComponent as ClockIcon } from '../../../../../../assets/clock-fill.svg'
import { ReactComponent as DetectedLeaksIcon } from '../../../../../../assets/detected-leaks.svg'
import { ReactComponent as ResolvedLeaksIcon } from '../../../../../../assets/resolved-leaks.svg'
import { ReactComponent as UnresolvedLeaksIcon } from '../../../../../../assets/unresolved-leaks.svg'

import { Title } from '../../../../../../components'
import { Colors } from '../../../../../../styles/colors'
import { SPACES } from '../../../../../../styles/spaces'
import TitleSubtitleWrapper from '../../../common/TitleSubtitleWrapper'
import LeaksFrame from './LeaksFrame'

interface IProps {
	leaksPeopleSummary: IGetPeopleLeaksOverview
}

const TRANSLATE_LEAKS_OVERVIEW_BASE_KEY = 'pdfExports.RiskManagementExport.credentialLeakMonitoring'

const CredentialLeakMonitoring: React.FC<IProps> = ({ leaksPeopleSummary }) => {
	const { t } = useTranslation()

	const { detectedCount, unresolvedCount, resolvedCount } = useMemo(() => {
		if (!leaksPeopleSummary) {
			return {
				detectedCount: 0,
				resolvedCount: 0,
				unresolvedCount: 0,
			}
		}

		return {
			detectedCount: leaksPeopleSummary.leaks.length,
			unresolvedCount: leaksPeopleSummary.leaks.filter(
				(leak) => leak.status === LeakStatus.Discovered,
			).length,
			resolvedCount: leaksPeopleSummary.leaks.filter(
				(leak) => leak.status !== LeakStatus.Discovered,
			).length,
		}
	}, [leaksPeopleSummary])

	return (
		<Wrapper>
			<Title color={Colors.Dark} style={{ marginBottom: SPACES.DEFAULT }}>
				{t(`${TRANSLATE_LEAKS_OVERVIEW_BASE_KEY}.title`)}
			</Title>
			<LeakWrapper>
				<TitleSubtitleWrapper
					title={t(`${TRANSLATE_LEAKS_OVERVIEW_BASE_KEY}.title`)}
					subtitle={t(`${TRANSLATE_LEAKS_OVERVIEW_BASE_KEY}.subtitle`)}
				/>
				<Section>
					<LeaksFrame
						leaksCount={detectedCount}
						description={t(`${TRANSLATE_LEAKS_OVERVIEW_BASE_KEY}.detected`)}
						icon={<DetectedLeaksIcon />}
						backgroundColor={Colors.RedNew}
					/>
					<LeaksFrame
						leaksCount={unresolvedCount}
						description={t(`${TRANSLATE_LEAKS_OVERVIEW_BASE_KEY}.unresolved`)}
						icon={<UnresolvedLeaksIcon />}
						backgroundColor={Colors.OrangeNew}
					/>
					<LeaksFrame
						leaksCount={resolvedCount}
						description={t(`${TRANSLATE_LEAKS_OVERVIEW_BASE_KEY}.resolved`)}
						icon={<ResolvedLeaksIcon />}
						backgroundColor={Colors.GreenNew}
					/>
				</Section>
				<Notification>
					<ClockIcon style={{ height: '32px', width: '32px' }} />
					{t(`${TRANSLATE_LEAKS_OVERVIEW_BASE_KEY}.notification`)}
				</Notification>
			</LeakWrapper>
		</Wrapper>
	)
}

const Wrapper = styled.section`
	display: flex;
	flex-direction: column;
`
const LeakWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${SPACES.EXTRA_LARGE} 48px;
	border-radius: ${SPACES.DEFAULT};
	background: ${Colors.White};
`
const Section = styled.div`
	display: flex;
	justify-content: space-between;
	margin-block: ${SPACES.DEFAULT};
`

const Notification = styled.div`
	margin-inline: -24px;
	display: flex;
	padding: ${SPACES.SMALL} ${SPACES.DEFAULT};
	background: ${Colors.GrayNew};
	border-radius: ${SPACES.SMALL};
	font-size: ${SPACES.SMALL};
	color: ${Colors.OrangeNew};
	gap: ${SPACES.SMALL};
	align-items: center;
`
export default CredentialLeakMonitoring
