import axios from 'axios'
import { ICourse, ILesson } from 'blue-project-types'
import { API_LEARNING_URL } from '../../../config/urls'
import {
	ICourseLearnerData,
	ICreateCourse,
	ICreateEditPage,
	ICreateEditLesson,
	IGeneratePreviewLinkData,
	IRemoveLearnerData,
	IRemoveLessonPage,
	IResendEmailsData,
	ISendReminderData,
	IUpdateLearnerData,
	IPredefinedCourseCreateEditForm,
	ICreateEditNanoPage,
	ICopyCourse,
} from './types'

export const FETCH_LESSONS_KEY = 'fetchLessons'
export const fetchLessons = async () => {
	const response = await axios.get(`${API_LEARNING_URL}/lessons`)

	return response.data
}

export const CREATE_COURSE_KEY = 'createCourse'
export const createCourse = async (data: ICreateCourse) => {
	const response = await axios.post(`${API_LEARNING_URL}/courses`, data)

	return response.data
}

export const COPY_COURSE_KEY = 'copyCourse'
export const copyCourse = async (data: ICopyCourse) => {
	const response = await axios.post(`${API_LEARNING_URL}/courses/copy`, data)

	return response.data
}

export const FETCH_COURSE_KEY = 'fetchCourse'
export const fetchCourse = async (id: string) => {
	const response = await axios.get(`${API_LEARNING_URL}/courses/${id}`)

	return response.data
}

export const FETCH_COURSES_KEY = 'fetchCourses'
export const fetchCourses = async () => {
	const response = await axios.get(`${API_LEARNING_URL}/courses`)

	return response.data
}

export const COUNT_COURSES_KEY = 'countCourses'
export const countCourses = async () => {
	const response = await axios.get(`${API_LEARNING_URL}/courses/count`)

	return response.data
}

export const UPDATE_COURSE_KEY = 'updateCourse'
export const updateCourse = async ({ id, ...data }: Partial<ICourse>) => {
	const response = await axios.patch(`${API_LEARNING_URL}/courses/${id}`, data)

	return response.data
}

export const REMOVE_LEARNER_KEY = 'removeLearner'
export const removeLearner = async (data: IRemoveLearnerData) => {
	const response = await axios.delete(
		`${API_LEARNING_URL}/courses/${data.courseId}/learners/${data.learnerId}`,
	)

	return response.data
}

export const UPDATE_LEARNER_KEY = 'updateLearner'
export const updateLearner = async (data: IUpdateLearnerData) => {
	const { courseId, learnerId, ...updater } = data
	const response = await axios.patch(
		`${API_LEARNING_URL}/courses/${courseId}/learners/${learnerId}`,
		updater,
	)

	return response.data
}

export const EXTEND_LEARNERS_DURATION_KEY = 'extendLearnersDuration'
export const extendLearnersDuration = async ({ data, courseId }) => {
	const response = await axios.patch(
		`${API_LEARNING_URL}/courses/${courseId}/learners/extend-duration`,
		data,
	)

	return response.data
}

export const ADD_LEARNERS_KEY = 'addLearners'
export const addLearners = async ({ courseId, data }) => {
	const response = await axios.post(`${API_LEARNING_URL}/courses/${courseId}/learners`, data)

	return response.data
}

export const FETCH_LEARNER_QUIZ_DETAILS_KEY = 'fetchUserQuizDetails'
export const fetchLearnerQuizDetails = async (data: ICourseLearnerData) => {
	const response = await axios.get(
		`${API_LEARNING_URL}/courses/${data.courseId}/learners/${data.learnerId}/quiz-details`,
	)

	return response.data
}

export const FETCH_COURSE_LEARNERS_STATISTICS_KEY = 'fetchCourseLearnersStatistics'
export const fetchCourseLearnersStatistics = async (courseId: string) => {
	const response = await axios.get(`${API_LEARNING_URL}/courses/${courseId}/learners/statistics`)

	return response.data
}

export const SEND_REMINDER_KEY = 'sendReminder'
export const sendReminder = async ({ courseId, ...data }: ISendReminderData) => {
	const response = await axios.post(
		`${API_LEARNING_URL}/courses/${courseId}/learners/send-reminder`,
		data,
	)

	return response.data
}

export const RESEND_INVITATION_KEY = 'resendInvitation'
export const resendInvitation = async ({ courseId, ...data }: IResendEmailsData) => {
	const response = await axios.post(
		`${API_LEARNING_URL}/courses/${courseId}/learners/resend-invitation`,
		data,
	)

	return response.data
}

export const GENERATE_PREVIEW_LINK_KEY = 'generatePreviewLink'
export const generatePreviewLink = async ({ courseId, lessonId }: IGeneratePreviewLinkData) => {
	const response = await axios.get(
		`${API_LEARNING_URL}/courses/${courseId}/lessons/${lessonId}/generate-preview-link`,
	)

	return response.data
}

export const FETCH_TENANT_LESSONS_KEY = 'fetchTenantLessons'
export const fetchTenantsLessons = async (tenantId: string) => {
	const response = await axios.get(`${API_LEARNING_URL}/tenants-lessons/${tenantId}`)

	return response.data
}

export const FETCH_PAGE_KEY = 'fetchPage'
export const fetchPage = async (lessonId: string, pageId: string) => {
	const response = await axios.get(`${API_LEARNING_URL}/lessons/${lessonId}/pages/${pageId}`)

	return response.data
}

export const CREATE_PAGE_KEY = 'createPage'
export const createPage = async (lessonId: string, data: ICreateEditPage) => {
	const response = await axios.post(`${API_LEARNING_URL}/lessons/${lessonId}/pages`, data)

	return response.data
}

export const EDIT_PAGE_KEY = 'editPage'
export const editPage = async (lessonId: string, pageId: string, data: ICreateEditPage) => {
	const response = await axios.put(`${API_LEARNING_URL}/lessons/${lessonId}/pages/${pageId}`, data)

	return response.data
}

export const FETCH_LESSONS_LMS_KEY = 'fetchLessonsLms'
export const fetchLessonsLms = async () => {
	const response = await axios.get(`${API_LEARNING_URL}/lessons/lms/all`)

	return response.data
}

export const FETCH_NANO_PAGES_KEY = 'fetchNanoPages'
export const fetchNanoPages = async () => {
	const response = await axios.get(`${API_LEARNING_URL}/nano-pages/all`)

	return response.data
}

export const CREATE_NANO_PAGE_KEY = 'createNanoPage'
export const createNanoPage = async (data: ICreateEditNanoPage) => {
	const response = await axios.post(`${API_LEARNING_URL}/nano-pages`, data)

	return response.data
}
export const CREATE_LESSON_KEY = 'createLesson'
export const createLesson = async (data: ICreateEditLesson) => {
	const response = await axios.post(`${API_LEARNING_URL}/lessons`, data)

	return response.data
}

export const REMOVE_LESSON_KEY = 'removeLesson'
export const removeLesson = async (id: string) => {
	const response = await axios.delete(`${API_LEARNING_URL}/lessons/${id}`)

	return response.data
}

export const REMOVE_LESSON_PAGE_KEY = 'removeLessonPage'
export const removeLessonPage = async ({ lessonId, pageId }: IRemoveLessonPage) => {
	const response = await axios.delete(`${API_LEARNING_URL}/lessons/${lessonId}/pages/${pageId}`)

	return response.data
}

export const FETCH_ONGOING_COURSES_KEY = 'fetchOngoingCourses'
export const fetchOngoingCourses = async (lessonId: string) => {
	const response = await axios.get(`${API_LEARNING_URL}/lessons/${lessonId}/ongoing-courses`)

	return response.data
}

export const FETCH_LESSON_KEY = 'fetchLesson'
export const fetchLesson = async (id: string) => {
	const response = await axios.get(`${API_LEARNING_URL}/lessons/${id}`)

	return response.data
}

export const UPDATE_LESSON_KEY = 'updateLesson'
export const updateLesson = async (id: string, data: Partial<ICreateEditLesson>) => {
	const response = await axios.put(`${API_LEARNING_URL}/lessons/${id}`, data)

	return response.data
}

export const UPDATE_LESSON_PAGES_ORDER_KEY = 'updateLessonPagesOrder'
export const updateLessonPagesOrder = async ({ id, ...data }: Partial<ILesson>) => {
	const response = await axios.patch(`${API_LEARNING_URL}/lessons/${id}/pages/update-order`, data)

	return response.data
}

export const FETCH_ORGANIZATION_LESSONS_LMS_KEY = 'fetchOrganizationLessons'
export const fetchOrganizationLessons = async () => {
	const response = await axios.get(`${API_LEARNING_URL}/lessons/lms/organization-lessons`)

	return response.data
}

export const FETCH_ORGANIZATION_NANO_PAGES_KEY = 'fetchOrganizationNanoPages'
export const fetchOrganizationNanoPages = async () => {
	const response = await axios.get(`${API_LEARNING_URL}/nano-pages/organization-nano-pages`)

	return response.data
}

export const COPY_LESSON_KEY = 'copyLesson'
export const copyLesson = async (id: string) => {
	const response = await axios.post(`${API_LEARNING_URL}/lessons/${id}/copy`)

	return response.data
}

export const FETCH_PREDEFINED_COURSES_KEY = 'fetchPredefinedCourses'
export const fetchPredefinedCourses = async () => {
	const response = await axios.get(`${API_LEARNING_URL}/predefined-courses`)

	return response.data
}

export const FETCH_PREDEFINED_COURSE_KEY = 'fetchPredefinedCourse'
export const fetchPredefinedCourse = async (predefinedCourseId: string) => {
	const response = await axios.get(`${API_LEARNING_URL}/predefined-courses/${predefinedCourseId}`)

	return response.data
}

export const CREATE_PREDEFINED_COURSE_KEY = 'createPredefinedCourse'
export const createPredefinedCourse = async (data: IPredefinedCourseCreateEditForm) => {
	const response = await axios.post(`${API_LEARNING_URL}/predefined-courses`, data)

	return response.data
}

export const UPDATE_PREDEFINED_COURSE_KEY = 'updatePredefinedCourse'
export const updatePredefinedCourse = async ({ predefinedCourseId, ...data }) => {
	const response = await axios.put(
		`${API_LEARNING_URL}/predefined-courses/${predefinedCourseId}`,
		data,
	)

	return response.data
}

export const REMOVE_PREDEFINED_COURSE_KEY = 'removePredefinedCourse'
export const removePredefinedCourse = async (predefinedCourseId: string) => {
	const response = await axios.delete(
		`${API_LEARNING_URL}/predefined-courses/${predefinedCourseId}`,
	)

	return response.data
}

export const FETCH_ORGANIZATION_PREDEFINED_COURSES_KEY = 'fetchOrganizationPredefinedCourses'
export const fetchOrganizationPredefinedCourses = async () => {
	const response = await axios.get(`${API_LEARNING_URL}/predefined-courses/organization-courses`)

	return response.data
}

export const REMOVE_REQUEST_INVITATION_KEY = 'removeRequestInvitation'
export const removeRequestInvitation = async ({ courseId, invitationId }) => {
	const response = await axios.delete(
		`${API_LEARNING_URL}/courses/${courseId}/request-invitations/${invitationId}`,
	)

	return response.data
}

export const CREATE_REQUEST_INVITATION_KEY = 'createRequestInvitation'
export const crateRequestInvitation = async (courseId: string) => {
	const response = await axios.post(`${API_LEARNING_URL}/courses/${courseId}/request-invitations`)

	return response.data
}

export const REMOVE_NANO_PAGE_KEY = 'removeNanoPage'
export const removeNanoPage = async (nanoPageId: string) => {
	const response = await axios.delete(`${API_LEARNING_URL}/nano-pages/${nanoPageId}`)

	return response.data
}

export const FETCH_NANO_PAGE_KEY = 'fetchNanoPage'
export const fetchNanoPage = async (nanoPageId: string) => {
	const response = await axios.get(`${API_LEARNING_URL}/nano-pages/${nanoPageId}`)

	return response.data
}

export const EDIT_NANO_PAGE_KEY = 'editNanoPage'
export const editNanoPage = async (nanoPageId: string, data: ICreateEditNanoPage) => {
	const response = await axios.put(`${API_LEARNING_URL}/nano-pages/${nanoPageId}`, data)

	return response.data
}

export const FETCH_LEARNING_CONTENT_TAGS_KEY = 'fetchLearningContentTags'
export const fetchLearningContentTags = async () => {
	const response = await axios.get(`${API_LEARNING_URL}/learning-content-tags`)

	return response.data
}

export const FETCH_ONGOING_CAMPAIGNS_KEY = 'fetchOngoingCampaigns'
export const fetchOngoingCampaigns = async (nanoPageId: string) => {
	const response = await axios.get(`${API_LEARNING_URL}/nano-pages/${nanoPageId}/ongoing-campaigns`)

	return response.data
}

export const FETCH_COURSE_STANDARDS_KEY = 'fetchCourseStandards'
export const fetchCourseStandards = async (id: string) => {
	const response = await axios.get(`${API_LEARNING_URL}/courses/${id}/course-standards`)

	return response.data
}

export const FETCH_PEOPLE_AWARENESS_OVERVIEW_KEY = 'fetchPeopleAwarenessOverview'
export const fetchPeopleAwarenessOverview = async () => {
	const response = await axios.get(`${API_LEARNING_URL}/people-overview`)

	return response.data
}
