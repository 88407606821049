import { IPersonRiskScoreProperty } from 'blue-project-types'

export const calculateTotalScoreReduction = (properties: IPersonRiskScoreProperty[]) =>
	properties.reduce((total, current) => (total += current.scoreReduction), 0)

export const calculateRiskScoreChange = (lastMonthsRiskScore: number, riskScore: number) => {
	if (lastMonthsRiskScore === undefined || lastMonthsRiskScore === null) {
		return
	}

	if (lastMonthsRiskScore === 0) {
		return riskScore > 0 ? 100 : 0
	}

	const change = ((riskScore - lastMonthsRiskScore) / lastMonthsRiskScore) * 100
	return Math.round(change)
}
